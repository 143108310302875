import _refiner from 'refiner-js';
_refiner('setProject', 'acad7f30-270f-11ef-b31d-07b50603e296');  

export const defineUserRefiner = ({
    id, email, name
}: {
    id?: string |number;
    email?: string;
    name?: string
}) =>{
    _refiner('identifyUser', {
        id, // Replace with your user ID
        email, // Replace with user Email
        name, // Replace with user name
    });
}

export const showFormCancelCTARefiner = () => {
    _refiner("showForm", "5cda8790-2710-11ef-ba8a-6d793c7dcedf");
}

export const showFormCSATRefiner = () => {
    _refiner("showForm", "21e4f6e0-2710-11ef-b87d-157177d4f11e");
}